/* You can add global styles to this file, and also import other style files */
/* This rule must stay in the first place to make the client's personalized fonts work */
p,
a,
h1,
h2,
h3,
h4,
h5,
h6,
span {
  font-family: 'Quicksand', sans-serif;
}

/* You can add global styles to this file, and also import other style files */

@import '../src/app/components/variables.scss';

* {
  outline: 0 !important;
}

:focus {
  outline: 0 !important;
}

*:focus {
  outline: none;
  box-shadow: none !important;
}

p {
  font-size: 0.8rem;
  font-weight: 300;
}

span {
  font-size: 0.8rem;
}

html,
body {
  height: 100%;
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

b,
strong {
  font-weight: bold;
}

.btn {
  letter-spacing: 0.5px;
  min-height: 30px;
  border-radius: 3px;
  padding: 5px 20px;
  font-size: 0.9rem;
}
.btn-sidebar{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #BFD8FF;
  height: 30px;
  width: 30px;
  border-radius: 5px;
  padding: 5px;
  color: $_TEXT_PRIMARY_COLOR;
  font-weight: 700;
  margin-right: 20px;
  &:last-child{
    margin-right: 0px;
  }
}

.btn-primary {
  background-color: $_PRIMARY_COLOR;
  color: white;
  border: none;

  .dropdown-toggle:focus {
    background-color: $_PRIMARY_COLOR;
    border: none;
  }

}



.btn-primary:hover {
  background-color: $_PRIMARY_COLOR;
  border-color: $_PRIMARY_COLOR;
  color: white;
}

.btn-primary:focus {
  // background-color: $_PRIMARY_COLOR;
  border-color: none;
  // color: white;
}

.btn-primary:active {
  background-color: $_PRIMARY_COLOR !important;
  border-color: none;
  color: white;
}

.show>.btn-primary.dropdown-toggle {
  background-color: $_TOOLBAR_AUX_COLOR !important;
  border-color: none;
  color: white;
}

.show>.btn-primary.dropdown-toggle:active {
  background-color: $_TOOLBAR_AUX_COLOR !important;
  border-color: none;
  color: white;
}

.dropdown-menu {
  border: 1px solid rgba(0, 0, 0, .05);

  &.show {
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08) !important;
  }
}


%btn-skeleton {
  padding: 0px 25px;
  line-height: 40px;
  font-size: 12pt;
  font-weight: 400;
  letter-spacing: 1px;
}

.btn-main {
  @extend %btn-skeleton;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  background-color: $_PRIMARY_COLOR_ACTIVE;  
  border-radius: 17px;  
  font-weight: 600;
  &:hover {
    background-color: $_PRIMARY_COLOR_HOVER;
    color: white;
  }
}

.btn-outline {
  @extend %btn-skeleton;
  color: $_TEXT_PRIMARY_COLOR;
  background-color: transparent;

  &:hover {
    background-color: $_TEXT_COLOR_LIGHT;
    color: white;
  }
}

.btn-outline-delete {
  @extend %btn-skeleton;
  color: $_TEXT_PRIMARY_COLOR;
  background-color: transparent;
  border: 1px solid $_TEXT_COLOR_LIGHT;

  &:hover {
    border: 1px solid $_AUX_COLOR_RED;
    background-color: $_AUX_COLOR_RED;
    color: white;
  }
}

.btn-naked {
  @extend %btn-skeleton;
  background-color: transparent;
  border-radius: 17px;
  font-weight: 600;
  &:hover {
    background-color: #eaeaef;
    border: 1px solid #eaeaef;
  }
}

.btn-and-icon {
  @extend %btn-skeleton;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .bt-icon {
    width: 14px;
    height: 14px;
    margin-left: 5px;
  }
}

.btn-relief {
  margin: 5px auto;
  background-color: white;
  box-shadow: $_BORDER_AND_SHADOW;
  line-height: 25px;
  color: $_TEXT_PRIMARY_COLOR;

  &:hover {
    color: $_PRIMARY_COLOR;
  }

  &:focus {
    color: inherit;
    box-shadow: $_BORDER_AND_SHADOW !important;
  }
}

.btn-icon {
  border-radius: 4px;
  border: 1px solid #cdd7e0;
  width: 36px;
  height: 38px;
  cursor: pointer;
  transition: 0.1s ease-in;

  &:hover {
    background-color: #eee;
  }

  span {
    display: block;
    margin: 0 auto;
  }
}

.btn-text-icon {
  border-radius: 4px;
  border: 1px solid #cdd7e0;
  min-width: 100px;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 10pt;
  font-weight: 100;
  color: $_TEXT_COLOR_LIGHT;

  &:focus {
    //background-color: $_AUX_GRAY_COLOR;
    border: 1px solid $_PRIMARY_COLOR;
    color: $_TEXT_PRIMARY_COLOR;
  }

  &:hover {
    cursor: pointer;
    color: $_TEXT_PRIMARY_COLOR;
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.1);
  }

  span {
    margin-left: 5px;
  }
}

.new-element-icon {
  cursor: pointer;
  color: $_TEXT_PRIMARY_COLOR;
  font-size:22px !important;
}


a {
  &.disabled {
    color: $_SECONDARY_COLOR_DARK;
    text-decoration: none;

    &:hover {
      cursor: default;
    }
  }
}

.btn-as-link {
  color: #007bff;
  text-decoration: none;
  outline: none;
  border: none;
  background-color: transparent;
  font-size: 10pt;
  margin: 15px 10px 0px 0;
  display: inline-block;
  text-underline-position: under;

  &:hover {
    color: #0056b3;
    text-decoration: underline;
    cursor: pointer;
  }
}

.alert {
  width: 90%;
  height: 100px;
  color: white;
  position: absolute;
  top: 0;
  border-radius: 2px;
  margin-top: 10px;
  font-family: 'Roboto';
  font-size: 1.1em;

  p {
    margin: 30px;
    display: inline-block;
  }
}

.alert-error {
  background-color: rgba(225, 82, 21, 0.673);
}

.alert-success {
  background-color: rgba(44, 143, 84, 0.714);
}


input[type=password] {
  border: 1px solid gray;
  border-radius: 3px;
  margin-bottom: 10px;
  margin-top: 10px;
  height: 35px;
  width: 70%;
  padding: 10px;
}

input[type=text] {
  border: 1px solid gray;
  border-radius: 3px;
  margin-bottom: 10px;
  margin-top: 10px;
  height: 35px;
  width: 70%;
  padding: 10px;
}

input[type=email] {
  border: 1px solid gray;
  border-radius: 3px;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 35px;
  width: 70%;
  padding: 10px;
}

.custom-input:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

pagination {
  .page-item.active {
    .page-link {
      background-color: #e9ecef;
      border-color: #dee2e6;
      color: $_SECONDARY_COLOR_DARK;
    }
  }

  .page-link {
    font-size: 10pt;
    color: $_SECONDARY_COLOR_DARK;

    &:hover {
      color: $_SECONDARY_COLOR_DARK;
    }
  }
}

.row-options__edit {
  border: none;
  background-color: transparent;
  padding: 10px;
  border-radius: 4px;

  &:hover {
    background-color: #eeeeee;
    cursor: pointer;
  }

  .row-options__edit__icon {
    display: block;
    background: url("/assets/table-row-edit-icon@2x");
    width: 15px;
    height: 15px;
  }
}

.section-info__header {
  padding: 10px;
  margin: 0px 0px 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ededed;

  .section__header {
    font-size: 9pt;
    font-weight: 300;
    text-transform: uppercase;
    color: #4a596a;
    margin-bottom: 0px;
  }

  .row-options__edit {
    padding: 0px;
  }
}

.mat-tab-group.mat-primary .mat-tab-label:focus,
.mat-tab-group.mat-primary .mat-tab-link:focus,
.mat-tab-nav-bar.mat-primary .mat-tab-label:focus,
.mat-tab-nav-bar.mat-primary .mat-tab-link:focus {
  background-color: white;
}

.mat-tab-body-wrapper {
  height: 100%;
}

.mat-tab-label,
.mat-tab-link {
  color: #4a596a;
  opacity: 1 !important;
}

.mat-tab-label-active {
  font-weight: 600;
}


.modal {
  z-index: 9999;
}

.modal-backdrop {
  background-color: #0c0e15;
  z-index: 9999;
}

.modal-backdrop.show {
  opacity: 0.15;
}

.modal-header {
  background-color: $_PRIMARY_COLOR_LIGHT;
  border: none;
  padding: 20px;

  .modal-title {
    color: $_TEXT_PRIMARY_COLOR;
    font-size: 11pt;
    font-weight: 700;

    span {
      color: #ffffff;
      padding: 5px 10px;
      border-radius: 10px;
      font-weight: 100;
      margin-left: 5px;

      &.REGISTRATION {
        background-color: $_AUX_COLOR_BLUE;
      }

      &.PASS {
        background-color: $_AUX_COLOR_GREEN;
      }

      &.KO {
        background-color: $_AUX_COLOR_RED;
      }

      &.PENDING {
        background-color: $_AUX_COLOR_ORANGE;
      }

      &.TEMPLATE {
        background-color: $_SECONDARY_COLOR_DARK;
      }
    }
  }

  .close span {
    font-size: 18pt;
    color: #42416a;
  }
}

.modal-body {

  .cke_top,
  .cke_bottom {
    //background: #ebecf1;
    //background-image: linear-gradient(to bottom,#fafafd,#e7e8ec);
  }

  .cke_contents.cke_reset {
    height: 300px !important;
  }

}

.modal-content {
  border: none;
  box-shadow: 0px 10px 80px 0px (rgba($color: $_SECONDARY_COLOR_DARK, $alpha: 0.2));
  border-radius: 10px;
}

#addTabModel {
  position: absolute;
  width: 100%;
  max-width: 420px;
  transform: translateX(-50%);
  left: 50%;
  top: 25%;
}

.modal-body__interviews {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.modal-body__interviews button {
  height: 35px;
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 860px;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 860px;
    margin: 1.75rem auto;

    &.share-modal {
      max-width: 500px;

      .modal-body {
        padding: 30px;
        text-align: center;

        p {
          font-size: 16px;
          margin-bottom: 20px;
        }
      }
    }
  }
}


.modal-dialog.prevent-dialog {
  max-width: 400px;
  top: 45%;
  transform: translateY(-50%) !important;
  margin: 0 auto;

  .modal-header {
    background-color: white;
  }

  .modal-body {
    span {
      font-weight: 700;
    }
  }

  .modal-footer {
    padding: 20px;
    border-top: 0px;
    justify-content: space-between;

    .btn {
      margin: 0;
    }

    .btn-main {
      padding: 0 15px;
    }

    .btn-naked {
      padding: 0 15px;
    }
  }
}

.modal-variable {
  color: $_PRIMARY_COLOR;
}

.toolbar{
  display: flex;
  justify-content: flex-end;
  height: 60px;
  .personal-settings{
    padding: 20px 15px;
    display: flex;
    align-items: center;
    .user-account{
      display: flex;
      align-items: center;
      .material-icons{
        color: $_TEXT_PRIMARY_COLOR;
        font-size: 25px;
      }
      .btn-outline{
        padding-left: 5px;
        padding-right: 0px;
      }
    }

  }
}

.bubble {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  background-color: $_PRIMARY_COLOR_ACTIVE;
  border-radius: 50px;
  line-height: 1;
  color: $_TEXT_PRIMARY_COLOR;
}

.bubble-zone {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  padding: 3px 7px;
  font-size: 12px;
  text-align: center;
  background-color: $_PRIMARY_COLOR_ACTIVE;
  border-radius: 50px;
  line-height: 1;
  color: $_TEXT_PRIMARY_COLOR;
}

.tooltip {
  position: relative;
  display: inline-block;
  width: 15px;
  height: 15px;
  margin: 0 5px;
  background-color: black;
  opacity: 0.3;
  border-radius: 50%;
  white-space: pre-line;

  &::after {
    content: 'i';
    position: absolute;
    display: block;
    color: white;
    font-size: 7pt;
    width: 15px;
    height: 15px;
    left: 0;
    top: 1px;
    text-align: center;
  }
}

.blue-label {
  font-size: 10pt;
  color: $_AUX_COLOR_BLUE;
}

%hint-block {
  width: 100%;
  min-height: 60px;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  margin: 15px 0px;
  font-size: 9pt;
  font-weight: 300;
  border-radius: 4px;
}

.hint-block--green {
  @extend %hint-block;
  color: #3c7d92;
  background-color: #ebf4f7;
  border: 1px solid #9ac6d9;

  span {
    font-weight: 700;
    margin-right: 5px;
  }
}

.hint-block--purple {
  width: 93%;
  margin: 20px;
  @extend %hint-block;
  color: #67658f;
  background-color: $_BACKGROUND_PURPLE_LIGHT;
  border: 1px solid #b7b1ef;
}

.form-wrap.form-builder {
  display: flex;
  justify-content: space-between;
  position: inherit;

  &::after {
    display: none;
  }

  .cb-wrap {
    top: 0px;
    left: 0px;
    max-width: 320px;
    width: 100%;
    float: none;
    order: 1;
  }
}

.inscription-form-content {
  .form-wrap.form-builder {
    display: flex;
    justify-content: flex-end;
    position: inherit;

    &::after {
      display: none;
    }

    .cb-wrap {
      position: absolute;
      top: 0px;
      left: 0px;
      max-width: 320px;
      width: 100%;
      float: none;
      order: 1;
    }
  }
}


.stage-wrap {
  background-color: white;
  float: none;
  order: 2;
  max-width: 768px;
  width: 100%;
  padding: 25px;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, .05);

  input,
  textarea,
  select {
    box-shadow: none !important;
  }
}

.interviews-content {
  .nav-tabs {
    //border: 1px solid #dee2e6;
    margin-bottom: 20px;
    background-color: #fff;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);

    .nav-item {
      background-color: white;
      margin-bottom: 0px;
    }

    li.nav-item.active {
      background-color: white;
      margin-bottom: -1px;
    }

    .nav-link {
      border: none;
      border-radius: 0px;
      padding: 15px 65px;
      text-transform: uppercase;
      font-weight: 400;
      color: $_TEXT_PRIMARY_COLOR;
    }

    .nav-link.active {
      color: #7564e2;
      border-bottom: 2px solid #7564e2;
    }
  }
}


//WIZARD NAVIGATION
.wizard-navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 65px;
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 20px;
  margin-bottom: 10px;
  background-color: white;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, .05);

  h4 {
    margin-bottom: 0px;
  }

  .steps {
    display: flex;
  }

  .step-line {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 120px;
  }

  .line {
    width: 100%;
    background-color: gainsboro;
    height: 1px;
    position: absolute;
    top: 12px;
    left: 50%;
    z-index: 0;
  }

  .step {
    position: relative;

    .material-icons {
      display: none;
    }

    .step-id {
      //border: 1pt solid #edebf9;
      border: 1px solid #7468e0;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 24px;
      width: 24px;
      background-color: #ffffff;
      cursor: pointer;
      z-index: 1;
    }

    .step-active {
      //background-color: #edebf9;
      background-color: #7468e0;
      color: white;
      border: none;
    }

    .step-activated {
      background-color: #7468e0;
      border: none;
      color: white
    }

    .step-disabled {
      pointer-events: none;
      border-color: $_TEXT_COLOR_LIGHT;

      .material-icons {
        display: block;
        font-size: 16px;
        color: $_TEXT_PRIMARY_COLOR;
      }

      span {
        display: none;
      }
    }

    .step-name {
      //margin-left: 5px;
      color: $_TEXT_COLOR_LIGHT;
      font-size: 11px;
      text-align: center;
    }

    .step-name-active {
      color: $_TEXT_PRIMARY_COLOR;
      font-weight: 700;
    }
  }

  .cancel-wizard {
    line-height: 30px;
    padding: 0 15px;
  }
}

.mat-input-container {
  .mat-form-field-infix {
    border: none;
  }

  .mat-input-underline {
    display: none;
  }

  label {
    font-size: 14px;
    font-weight: 600;
  }

  .mat-select-trigger {
    border: 1px solid gray;
    min-height: 35px;
    border-radius: 3px;
    background-color: #fff;

    .mat-select-value {
      line-height: 30px;
      padding-left: 10px;
    }

    .mat-select-placeholder {
      color: rgba(0, 0, 0, .42);
    }
  }

  .mat-input-placeholder {
    color: transparent;
  }
}

.mat-input-container:first-of-type {
  margin-left: 0;
}

.mat-input-container:last-of-type {
  margin-right: 0;
}

.categories-select {
  .mat-input-container {
    max-width: 30%;
    margin: 0 2.5%;
  }
}

.candidate__job-select {
  .mat-form-field-wrapper {
    padding-bottom: 0;

    .mat-select-value {
      //max-width: initial;
    }
  }
}

//FORM RENDER INTERVIEWS
.form {
  input[type="file"] {
    max-width: 320px;
    border: none;
  }

  input {
    width: 100%;
    max-width: 380px;
    height: 40px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    padding: 0 10px;
    margin: 0px;
    font-size: 11pt;
    &:focus {
      border: none;
      box-shadow: 0 0 3px #4c83f2 !important;
    }

    &::placeholder {
      font-size: 14px;
      font-weight: 100;
      color: #8c929d;
    }
  }

  label {
    width: 100%;
  }

  select {
    min-width: 230px;
    height: calc(2.25rem + 2px);
    font-size: 10pt !important;
    border: 1px solid #ced4da;
    vertical-align: middle;
    background-color: white;
    line-height: 1.5 !important;
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  }

  textarea {
    min-width: 480px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    min-height: 80px;
    padding: 10px;

    &:focus {
      border: 1px solid $_PRIMARY_COLOR;
    }

    &::placeholder {
      font-size: 14px;
      font-weight: 100;
      color: #8c929d;
    }
  }

  .form-group {
    .radio {
      input {
        width: auto;
        height: auto;
      }

      label {
        width: auto;
        margin-bottom: 0px;
        padding-left: 10px;
      }
    }
  }

}

.form-group {

  .radio-group,
  .checkbox-group {
    margin-top: 10px;

    .radio {
      padding: 5px 0px;
      margin-right: 20px;
      display: flex;
      align-items: baseline;
    }

    .radio-inline {
      label {
        display: inline-block;
      }

      input {
        display: inline-block;
        width: 20px;
        height: 16px;
      }
    }

    label {
      margin: 0 15px 0 5px;
      font-size: 14px;
      width: auto;
    }

    .checkbox {
      input {
        width: 20px;
        height: 16px;
      }
    }

    .checkbox-inline {
      label {
        display: inline-block;
        margin: 0 15px 0 0;
        width: auto;
      }

      input {
        display: inline-block;
        width: 20px;
        height: 15px;
      }
    }
  }

  &[class*=field-inline],
  .checkbox-inline {
    .radio-group {
      display: flex;
      align-items: center;
    }

    .checkbox-group {
      display: flex;
      align-items: center;
    }
  }
}

.btn-bar {
  padding: 30px;
  width: 100%;
  text-align: center;

  button {
    width: 100%;
    height: 45px;
    max-width: 320px;
    margin: 0 auto;
    background-color: #3e93b7;
  }
}

.mat-progress-bar {
  border-radius: 3px;
}

//HIGHCHARTS
app-chart-pie {
  .highcharts-container {
    width: 100%;
    margin: 0;

    svg {
      width: 100%;
    }
  }
}

.highcharts-container {
  width: 100%!important;
  margin: auto;
  svg {
    width: 100%;
  }
}

app-chart-render {
  height: 100px;
}

.chart-header {
  font-size: 12pt;
  color: #000000;
  padding: 20px;
  font-weight: 700;
}


//SPINNER

.loader {
  color: $_PRIMARY_COLOR;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  z-index: 9999;
  align-items: center;
  font-size: 34px;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: $_PRIMARY_COLOR;
}

.unclickable {
  pointer-events: none;
}

//Landing builder
.control-element {
  label {
    margin-bottom: 0px;

    .mat-checkbox-inner-container {
      width: 15px;
      height: 15px;
    }
  }
}

// Image candidates
.img-round {
  border-radius: 50%;
}

.img-round-candidate {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.applied-filters {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  padding: 0px 25px;

  p {
    margin-bottom: 0px;
  }
}

// Create user select all categories
.select-all {
  mat-pseudo-checkbox {
    display: none !important;
  }
}

.tags {
  background-color: #e6e8ef;
  color: #7a83a4;
  border-radius: 3px;
  padding: 2px 4px;
  font-size: 11px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 5px;

  span {
    display: flex;
    align-items: center;

    i {
      font-size: 12px;
      margin-left: 5px;
    }
  }
}


.tags-filters {
  display: flex;
  flex-wrap: wrap;
  padding: 0px 25px;

  p {
    margin-bottom: 10px;
  }
}

.clickable {
  cursor: pointer;
}

// Candidate Details
.comment-content {
  textarea {
    width: 100%;
  }

  p.privacy-info {
    font-weight: bold;
    font-size: 14px;
    color: $_TEXT_PRIMARY_COLOR;
    margin-top: 30px;
    margin-bottom: 10px;
    display: inline-block;

    .tooltip {
      top: 3px;
    }
  }

  .comment-type {
    display: flex;
    align-items: baseline;

    input {
      margin-right: 5px;
    }

    label {
      margin-right: 20px;
    }
  }
}

.comments__tab_body {
  background: $_SIDEBAR_SETTINGS_COLOR;
  min-height: calc(100vh - 345px);
  height: 100%;
  padding: 20px;
}

.section__comment {
  margin-bottom: 30px;
  position: relative;

  .section__comment_content {
    padding: 15px 20px 1px;
    margin-bottom: 10px;
    border-radius: 14px;
    background: #fff;
    box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);

    p {
      font-size: 14px;
    }
  }

  .triangle {
    position: absolute;
    left: 20px;
    bottom: 19px;
    width: 23px;
  }

  .section__comment_author {
    font-weight: 600;
    padding-left: 10px;
  }

  .section__comment_date {
    color: $_TEXT_COLOR_LIGHT;
  }

}

.candidate-cv__content {
  object {
    width: 100%;
    height: calc(100vh - 145px);
    padding-right: 10px;
    padding-bottom: 50px;
  }
}

.section__element_title {
  &.form_label * {
    font-weight: 100 !important;
    color: #656979 !important;
    font-size: 13px !important;
    font-family: 'Roboto', sans-serif !important;
  }
}

.timeline_event__content {
  p {
    margin-bottom: 5px;
  }
}

.cdk-overlay-container {
  z-index: 999999999999;
}

#btn-sort mat-radio-button {
  padding: 10px;

  &:hover {
    cursor: pointer;
    background-color: #e7ebf3;
    border-radius: 3px;
    display: block;
  }

  .mat-radio-container {
    display: none;
  }

  .sort-icons {
    margin-right: 10px;
  }
}

// Selected item in list
.active-item {
  background-color: #e9edf7;
  border-left: 4px solid $_PRIMARY_COLOR;
}

.form-group-wrapper {
  .mat-input-container .mat-select-trigger {
    border: 1px solid #cdd4da;
  }
}

// Candiate status tag
.status-tag {
  display: flex;
  align-items: center;
  color: white;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;

  &.KO {
    color: $_AUX_COLOR_RED;
  }

  &.PASS {
    color: #00b776;

    .material-icons {
      color: $_AUX_COLOR_GREEN;
    }
  }

  &.PND {
    color: #e29f0d;

    .material-icons {
      color: $_AUX_COLOR_ORANGE;
    }
  }

  &.PENDING {
    color: #e29f0d;

    .material-icons {
      color: $_AUX_COLOR_ORANGE;
    }
  }

  & .material-icons {
    font-size: 11pt;
    margin-right: 5px;
  }
}

// Client sidebar common styles

.sidebar-container {
  width: $_SIDEBAR_WIDTH;
  height: 100%;
  background-color: $_SIDEBAR_SETTINGS_COLOR;

  .header {
    display: flex;
    align-items: center;
    padding: 25px 20px;

    .title-wrapper {
      max-width: 80%;
    }

    .title {
      font-size: 18pt;
      line-height: 1;
      color: $_TEXT_PRIMARY_COLOR;
      margin-bottom: 0px;
      padding: 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .client-menu {
      button {
        border: none;
        background-color: transparent;

        .actions-icon-big {
          display: inline;
          padding: 5px 15px;
          background-color: #eaeaef;
          border-radius: 3px;
        }
      }
    }
  }

  .list-elements {
    list-style-type: none;

    li {
      a {
        display: block;
        color: $_TEXT_PRIMARY_COLOR;
        text-decoration: none;
        padding: 5px 10px;
        border-radius: 4px;

        &:hover {
          background-color: #eaeaef;
        }
      }

      .active-link {
        background-color: #eaeaef;
      }
    }
  }

  .fa-bars {
    color: #8490b0;
  }
}

.project-candidates-counter-container{
  display: flex;
  align-items: center;
  justify-content: center;
  .material-icons{
    font-size: 17px;
    color: $_TEXT_PRIMARY_COLOR;
    margin-right: 5px;
  }
}

.actions-icon-big {
  width: 15px;
  height: 4px;
  display: block;
  background-image: url("./assets/actions-icon-big@2x.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 15px 4px;
  padding: 10px 20px;

  &:hover {
    cursor: pointer;
  }
}

// Custom buffered mat-progress-bar
.custom-progressbar {
  .mat-progress-bar-background {
    animation: none !important;
    background-image: none;
    background-color: #e9ecef;
  }

  .mat-progress-bar-buffer {
    background-color: $_AUX_COLOR_GREEN !important;
  }
}

.mat-progress-bar-buffer {
  background-color: $_PRIMARY_COLOR_LIGHT;
}

.funnel {
  .mat-progress-bar-fill {
    transform-origin: center !important;
  }
}

.mat-progress-bar-fill::after {
  background-color: $_PRIMARY_COLOR;

  // Instrument config
  .config-right {
    .mat-slider-thumb-label {
      top: -30px !important;
      width: 20px;
      height: 20px;

      .mat-slider-thumb-label-text {
        font-size: 10px;
      }
    }
  }
}

.mat-tooltip {
  white-space: pre;
}

.candidate-avatar {
  display: flex;
  width: 100px;
  min-width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #ecaf88;
  align-items: center;

  &.orange {
    background-color: #ecaf88;
  }

  &.green {
    background-color: #d6e2ac;
  }

  &.blue {
    background-color: #bbd7ed;
  }

  &.pink {
    background-color: #e6bcd0;
  }

  &.turquoise {
    background-color: #79d2d2;
  }

  &.purple {
    background-color: #cdc3f2;
  }

  .candidate-initials {
    color: white;
    margin-bottom: 0px;
    margin: 0 auto;
  }
}

.filter_options__container {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, .5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
}

//CKEditor fix trash styles when open/close email on wizard
.cke_screen_reader_only {
  height: 0px !important;
}

@media only screen and (max-width: 1440px) {
  .mat-radio-button-flex {
    .mat-radio-outer-circle {
      height: 15px;
      width: 15px;
    }

    .mat-radio-inner-circle {
      width: 76%;
      height: 76%;
    }

    .mat-radio-ripple {
      left: -17px;
      top: -17px;
    }
  }
}

.dashboard-client__filter__date {
  .client-analytics__date {
    span {
      color: #838dad;
      font-size: 20px;
    }
  }

  .mat-form-field-wrapper {
    padding-bottom: 0px;
  }

  .mat-form-field-infix {
    border-top: 0px;
    border-bottom: 0.5px solid $_TEXT_COLOR_LIGHT;
  }

  .mat-form-field-flex {
    width: 200px;
  }

  input {
    text-align: center;
  }

  .mat-input-container .mat-input-placeholder {
    color: $_TEXT_COLOR_LIGHT !important;
  }

  label .mat-input-placeholder {
    font-weight: 400 !important;
  }

  .mat-calendar-body .mat-calendar-body-selected {
    background: $_PRIMARY_COLOR !important;
  }

  .date-to-date {
    margin: 0px 20px 0px 15px;
  }
}

@media (orientation: landscape) {
  .mat-datepicker-content-touch .mat-calendar {
    width: 64vh;
    height: 73vh;
  }
}

.popup-appearance{
  background-color: $_PRIMARY_COLOR;
  color:white;
  border-radius: 8px !important;
}

.mat-slide-toggle-bar {
  position: relative;
  width: 45px !important;
  height: 27px !important;
  flex-shrink: 0;
  border-radius: 21px !important;
  background-color: $_PRIMARY_COLOR_LIGHT;
  border: 1.5px solid grey;
}

.mat-slide-toggle-thumb {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
  margin-top: 4.75px;
  border: 1.5px solid grey;
  margin-left: 3px;
}

.page-table-buttons{
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
