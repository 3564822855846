@import url('https://fonts.googleapis.com/css?family=Nunito|Roboto');
@import url('https://fonts.googleapis.com/css?family=Quicksand:400,500,600,700&display=swap');

$_INACTIVE_COLOR_FILL: #cdc6e5;
$_INACTIVE_COLOR_BORDER: #5941a9;

$_PRIMARY_COLOR:#1B76FF;
$_PRIMARY_COLOR_LIGHT: #DBE9FF;
$_PRIMARY_COLOR_ACTIVE: #BFD8FF;
$_SECONDARY_COLOR_LIGHT: #644bcc;
$_SECONDARY_COLOR_DARK: #1C3FFD;
$_PRIMARY_COLOR_HOVER: #70CDFF;

$_PRIMARY_DARK : #163671;

$_AUX_GRAY_COLOR: #f5f6f7;
$_DIV_LINE_COLOR: #ededed;

$_TEXT_PRIMARY_COLOR: #2E384D;
$_TEXT_COLOR_LIGHT: #8c929d;
$_TEXT_DARK_BLUE: #474470;

$_BACKGROUND_PURPLE_LIGHT: #f4f4fd;

/* ALERTS */
$_ERROR_COLOR: #ef4051;
$_SUCCESS_COLOR: #24a91c;
$_WARNING_COLOR: #e49115;

/* $_AUX_COLOR_LIGHT: #f8f8fb; */
$_AUX_COLOR_LIGHT: #F4F9FF;
$_AUX_COLOR_DARK: #ededed;
$_AUX_COLOR_BLUE: #4c83f2;
$_AUX_COLOR_RED: #EF7A85;
$_AUX_COLOR_GREEN: #45CB85;
$_AUX_COLOR_TURQUOISE: #3e93b7;
$_AUX_COLOR_ORANGE: #ffbf34;

$_AUX_COLOR_RED_LIGHT: #fdeae8;
$_AUX_COLOR_GREEN_LIGHT: #eaf6eb;
$_AUX_COLOR_ORANGE_LIGHT: #fff3e6;

$_TOOLBAR_HEIGHT: 40px;
$_TOOLBAR_COLOR:#1C3FFD;
$_TOOLBAR_TEXT_COLOR: #ffffff;
$_TOOLBAR_AUX_COLOR: #ffffff;


$_SIDEBAR_WIDTH: 270px;
/* $_SIDEBAR_SETTINGS_COLOR: #f8f8fb; */
$_SIDEBAR_SETTINGS_COLOR: #f8f9fa;
$_SECONDARY_BUTTON_COLOR: #b1b3bb;

//RESPONSIVE SIZES
$_MIN_LARGE_SCREEN: 1824px;

$_AVG_SCREEN: 1366px;
$_MIN_LAPTOP: 1200px;

$_MAX_TABLET: 1024px;
$_MIN_TABLET: 768px;

$_MAX_MOBILE: 640px;
$_MIN_MOBILE: 320px;

//LAYOUT SIZES
$_WIZARD_HEADER_HEIGHT: 65px;
$_CANDIDATE_DETAIL_WIDTH: 784px;
$_CANDIDATE_DETAIL_WIDTH_RESPONSIVE: 680px;

//BOX SHADOW
$_BORDER_AND_SHADOW: 0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15);
$_BORDER_AND_SHADOW_HOVER: 0 0 0 2px rgba(63, 63, 68, 0.05), 0 5px 10px 3px rgba(63, 63, 68, 0.10);


$_EVALUATED_COLOR: #558ff3;
$_IN_PROGRES_COLOR:#eac97b;
$_INVITED_COLOR: #163671;
